<template>
  <v-dialog
    v-model="show"
    :max-width="maxWidth"
    :fullscreen="fullscreen"
    :persistent="persistent"
    :retain-focus="retainFocus"
    scrollable
  >
    <v-card class="default-shadow">
      <v-card-title class="bottom-shadow pa-0 ma-0 shrink" primary-title>
        <v-layout column align-center style="width: 100%">
          <v-layout align-center :class="showCloseBtn ? 'justify-start' : 'justify-center'" style="width: 100%">
            <BooksyIconButton @click.prevent="show = false" v-if="showCloseBtn" class="ml-5"></BooksyIconButton>
            <v-flex :class="showCloseBtn ? 'py-4 ml-2 mr-5' : headerMargins" shrink>
              <div class="text-h6 font-weight-bold text-center">{{ title }}</div>
            </v-flex>
            <v-spacer v-if="showCloseBtn"></v-spacer>
          </v-layout>
          <v-layout align-center style="width: 100%">
            <slot name="header"></slot>
          </v-layout>
          <v-progress-linear
            v-if="loading"
            height="2"
            class="pa-0 ma-0"
            :indeterminate="true"
          ></v-progress-linear>
        </v-layout>
      </v-card-title>
      <v-card-text :class="[noPadding ? 'pa-0' : 'pa-4']">
        <slot name="content"></slot>
        <v-flex class="text-center">
          <span class="text-subtitle-1 black--text">{{ text }}</span>
        </v-flex>
      </v-card-text>
      <v-card-actions
        :class="['top-shadow shrink', noFooterPadding ? '' : 'pa-4']"
      >
        <slot name="footer">
          <v-layout :class="compact && 'column'">
            <slot name="actions"></slot>
            <v-spacer></v-spacer>
            <v-layout shrink :class="compact && 'column'">
              <DefaultButton
                v-if="positiveButtonText.length > 0"
                :class="compact ? 'mb-3' : 'my-0 order-last'"
                :block="compact"
                :primary="positiveButtonPrimary"
                :loading="loadingPositiveButton"
                :disabled="disabledPositiveButton"
                @click.prevent="onPositiveButtonClicked"
                >{{ positiveButtonText }}</DefaultButton
              >
              <DefaultButton
                v-if="negativeButtonText.length > 0"
                :block="compact"
                class="my-0"
                :bgColor="negativeButtonColor"
                :disabled="disabledNegativeButton"
                @click.prevent="onNegativeButtonClicked"
                >{{ negativeButtonText }}</DefaultButton
              >
            </v-layout>
          </v-layout>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
    },
    positiveAction: {
      type: Function,
      default: () => {},
    },
    negativeAction: {
      type: Function,
      default: () => {},
    },
    positiveButtonText: {
      default: "Usuń",
    },
    negativeButtonText: {
      default: "Zamknij",
    },
    negativeButtonColor: {
      default: "error"
    },
    positiveButtonPrimary: {
      default: true
    },
    title: {},
    text: {},
    maxWidth: {
      default: "400px",
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    headerMargins: {
      default: "py-4 px-5",
    },
    disabledPositiveButton: {
      type: Boolean,
      default: false,
    },
    disabledNegativeButton: {
      type: Boolean,
      default: false,
    },
    noFooterPadding: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    dontHideOnPositiveAction: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    retainFocus: {
      type: Boolean,
      default: true
    },
    loadingPositiveButton: {
      type: Boolean,
      default: false
    },
    showCloseBtn: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean, 
      default: true
    }
  },
  components: {
    BooksyIconButton: () => import("@/components/buttons/BooksyIconButton"),
    IconButton: () => import("@/components/buttons/IconButton"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    onPositiveButtonClicked() {
      this.positiveAction();
      if (!this.dontHideOnPositiveAction) {
        this.show = false;
      }
    },
    onNegativeButtonClicked() {
      this.negativeAction();
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  border-radius: 8px !important;
  background: white !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
.bottom-shadow {
  position: relative;
  z-index: 1 !important;
  margin-bottom: 4px;
  box-shadow: 0px 5px 8px -5px rgba(0, 0, 0, 0.1) !important;
}
.top-shadow {
  position: relative;
  z-index: 1 !important;
  box-shadow: 0px -5px 8px -5px rgba(0, 0, 0, 0.1) !important;
}
</style>
